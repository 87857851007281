import { useActiveOrganization } from "@/context/ActiveOrganizationProvider";
import { SIGNUP_WELCOME_URL } from "@/lib/constants";
import { DirectoryLayout } from "@components/layouts/DirectoryLayout";
import { SidebarLayout } from "@components/layouts/SidebarLayout";
import FilesDirectoriesView from "@components/llama/Projects/FilesDirectoriesView";
import { useRequireAuth } from "lib/use-auth";
import { useRouter } from "next/router";
import { ReactElement, useEffect } from "react";

const OrganizationSlugPage = () => {
  const router = useRouter();
  const { user } = useRequireAuth();
  const pageName = router.query.orgSlug as string;
  const { slug, organization, loading: loadingOrganization } = useActiveOrganization();

  useEffect(() => {
    if (!user || loadingOrganization) return;

    if (organization) {
      // If a user navigates to a root
      const subPages = ["tools", "project", "playground", "legacy-playground", "library"];
      if (subPages.includes(pageName)) {
        router.push(`/${slug}/${pageName}`);
      }
    } else {
      router.push(SIGNUP_WELCOME_URL);
    }
  }, [user, organization, pageName, slug, loadingOrganization, router]);

  if (!organization) {
    return null;
  }

  return (
    <div className="mx-auto w-full max-w-screen-xl px-32 pb-48 pt-24">
      <FilesDirectoriesView />
    </div>
  );
};

export default OrganizationSlugPage;

OrganizationSlugPage.getLayout = function getLayout(page: ReactElement) {
  return (
    <SidebarLayout>
      <DirectoryLayout>{page}</DirectoryLayout>
    </SidebarLayout>
  );
};
