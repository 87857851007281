import { useSidebarContext } from "@/context/SidebarContextProvider";
import { useCurrentLocationFromHref } from "@/lib/path-utils";
import { classNames } from "@/lib/utils";
import { useDirectory } from "@/services/directories.service";
import { SentryErrorBoundary } from "@components/error/SentryErrorBoundary";
import { DirectoryReadmeSidePanel } from "@components/llama/Projects/ReadmePanel";
import CustomHead from "@components/templates/CustomHead";
import React from "react";
import { AppHeader } from "./Sidebar/AppHeader/AppHeader";
import { useActiveOrganization } from "@/context/ActiveOrganizationProvider";

// Basically the FileLayout, but with different hooks and fewer sidepanels currently. Would be nice to have a single layout.
export const DirectoryLayout = ({ children }: React.PropsWithChildren<{}>) => {
  const currentLocation = useCurrentLocationFromHref();

  const { directory, isLoading } = useDirectory(currentLocation?.type === "directory" ? currentLocation.id : null);
  const { organization } = useActiveOrganization();

  const { sidePanelState, setSidePanelState, sidePanelOpen, setSidePanelOpen, sidebarCollapsed } = useSidebarContext();

  return (
    <>
      <div className="relative flex h-full flex-col @container">
        <AppHeader hideTabs />
        <div className="flex min-h-0 grow bg-background-base-3">
          <div className={classNames("flex min-h-0 grow flex-col overflow-auto bg-white", sidePanelOpen && "mr-8")}>
            <CustomHead title={isLoading ? "" : directory?.name || organization?.name || ""} />

            <div className="relative flex grow overflow-hidden bg-background-base-3">
              <main
                className={classNames(
                  "h-full min-w-0 grow overflow-y-auto rounded-tl-lg bg-white",
                  sidePanelState !== "closed" && "rounded-tr-lg",
                )}
              >
                <SentryErrorBoundary>
                  {/* // Directory ID key means components don't try to hold state between directories. */}
                  <React.Fragment key={directory?.id}>
                    <div className="h-full rounded-t-lg">{children}</div>
                  </React.Fragment>
                </SentryErrorBoundary>
              </main>
              {directory && <DirectoryReadmeSidePanel directoryId={directory.id} />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
